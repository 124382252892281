import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/general/logo.png';
export const Header = () => {
    return (
        <div className="container-fluid bg_black py-2">
            <div className='row g-0'>
                <div className="col-lg-4 d-flex justify-content-center align-items-center">
                    <nav className="navbar navbar-expand-lg">
                        <div className="container-fluid">
                            <div className="col-12 d-lg-none text-center">
                                <button className="navbar-toggler text-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <i className="fa-solid fa-bars"></i>
                                </button>
                            </div>
                            <div className="collapse navbar-collapse text-center" id="navbarNav">
                                <ul className="navbar-nav menu">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/why_us">
                                            Why Us
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="#">
                                            Brands
                                        </Link>
                                        <ul className="submenu">
                                            <li><Link to="/vipsportsbook">VIP Sportsbook</Link></li>
                                            <li><Link to="/betgigante">Bet Gigante</Link></li>
                                        </ul>
                                    </li>

                                    <li className="nav-item">
                                        <Link className="nav-link" to="/commissions">Commissions</Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link className="nav-link" to="/faqs">FAQ</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/contact_us">Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="col-lg-4 text-center">
                    <Link className="nav-link text-white active" aria-current="page" to="/">
                        <img className="w-5s0"
                            src={logo} alt='logo' loading="lazy"></img>
                    </Link>
                </div>
                <div className="col-lg-4 d-flex justify-content-center align-items-center">
                    <div className="text-white">
                        <div className="col-12">
                            <a href="#" target="_blank" type="button" className="btn boton_principal m-1" >
                                Login
                            </a>

                            <a className="btn boton_silver m-1" target="_blank" href="#">
                                Sign Up
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
